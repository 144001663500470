// Here you can add other styles

.myContainer {
  padding: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: #262d36;
}

.myApp {
  //border-radius: 30px !important;
  height: 100%;
  width: 100%;
  //max-width: 1700px;
  //max-height: 1100px;
  padding: 10px;
  box-shadow:  0px 30px 50px #111417;
}

.myContent{
  border-radius: 20px !important;
  background-color: #f5f5fbaa;
  padding: 20px;
}

.hoverable{
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-property: box-shadow;
  transition-property: box-shadow;
  box-shadow: inset 0 0 0 4px #f5f5fb, 0 0 1px rgba(0, 0, 0, 0);
  cursor: pointer;
}

.hoverable:hover{
  box-shadow: inset 0 0 0 4px #b2dbb5, 0 0 1px rgba(0, 0, 0, 0);
}

.hoverselect{
  box-shadow: inset 0 0 0 4px #6daf72, 0 0 1px rgba(0, 0, 0, 0);
}

.appFill{
  flex: 1 1 auto;
}

.crop-text-2 {
  -webkit-line-clamp: 2;
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

/* Grow */
.hvr-grow, .hvr-grow-sm{
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-property: transform;
  transition-property: transform;
  cursor: pointer;
}
.hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.hvr-grow-sm:hover, .hvr-grow-sm:focus, .hvr-grow-sm:active {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.hvr-simple{
  cursor: pointer;
}

.hvr-simple:hover, .hvr-simple:focus, .hvr-simple:active {
  background-color: #eee;
  transition: all 0.5s ease;
}

.shadowed {
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  -moz-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
  transition: all 0.5s ease;
}

.shadowed:hover{
  -webkit-filter: drop-shadow(10px 10px 10px #222);
  -moz-filter: drop-shadow(10px 10px 10px #222);
  filter: drop-shadow(10px 10px 10px #222);
}


.closeX{
  opacity:0.5
}

.closeX:hover{
  opacity:1
}

.mySearchbar{
  padding: 3px;
  min-width: 400px;
  width:30%;
  padding-left: 10px;
}

.mySidebar{
  height: 100%;
  max-width: 80px;
}

.myFooter{
  position:absolute;
  bottom: 0;
  right: 0;
}

:root {
  --avatar-size: 8rem;
  /* change this value anything, e.g., 100px, 10rem, etc. */
}

.circle {
  background-color: #3399ff;
  border-radius: 50%;
  height:6rem;
  width:6rem;
}

.initials {
  height: 60%;
  fill: white;
}

.historytext{
    white-space: pre-wrap;
}

.myTable td,th{
	padding: 5px;
	padding-right: 20px;
	/*min-width: 100px;*/
}


.myTab td, th{
  padding: 5px;
}

.myTable2 tr > *:nth-child(1) { width:20%; }
.myTable2 tr > *:nth-child(2) { width:20%; }
.myTable2 tr > *:nth-child(3) { width:20%; }
.myTable2 tr > *:nth-child(4) { width:20%; }
.myTable2 tr > *:nth-child(5) { width:20%; }

.myTable3 th > *:nth-child(1) { width:40%; }
.myTable3 th > *:nth-child(2) { width:40%; }
.myTable3 th > *:nth-child(3) { width:20%; }

.myTextArea{
  resize: none;
}

.textareaCont{
  position:absolute;
  bottom: 30px;
  left:30px;
  right:30px;
}

.patientDetails{
  -ms-flex: 0 0 350px;
    flex: 0 0 350px;
}

.patientElements{
  -ms-flex: 0 0 400px;
    flex: 0 0 400px;
}

.agendaToday{
  -ms-flex: 0 0 500px;
    flex: 0 0 500px;
}

.fullheighttab{
  height: calc(100vh - 250px)
}

.myrecordtab{
  height: calc(100% - 70px)
}


.roundedCard{
  border-radius: 20px !important;
  border: 1px solid;
  border-color: #dfdfdf;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.myhover:hover{
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  -webkit-transition: box-shadow 0.25s;
  transition: box-shadow 0.25s;
  cursor: pointer;
}

.shadows{
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.roundedCardHeader:first-child{
  border-radius: 20px 20px 0px 0px;
  padding: 20px;
}

.roundedCardBotRight{
  border-radius: 0px 0px 20px 0px;
  padding: 20px;
  background-color: #f5f5fb;
}

.roundedCardImage{
  border-radius: 20px 20px 0px 0px !important;
  
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

body{
  font-family: 'Ubuntu', sans-serif;
  //font-family: 'Montserrat', sans-serif;
}

.myround{
  border-radius: 10px;
}

.roundedIcon{
  border-radius: 20px;
  border: 0px;
}


.myPatientPanel{
  max-width: 600px; 
}

.clickable{
  cursor: pointer;
  border-width: 0px !important;
}

.click{
  cursor: pointer;
}

.clicked{
   border-width: 1px !important;
}

.modal-content  {
    -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
    border-radius: 20px !important; 
}

.cardBody{
  padding: 30px;
  padding-bottom: 10px;
  padding-top: 25px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-width: 2px !important;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 25px !important;
}

.myinfo {
  width: 400px;
  position:absolute;
  bottom: -40px;
  right: -450px;
  z-index:100;
  opacity: 0.7;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background-color: #EEE;
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
  background-color: #CCC;
  border-radius: 20px;
}

.noStyleInput{
  background-color: transparent;
  border: 0px;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

}

.noStyleInput:focus{
  background-color: transparent;
  border: 0px;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

}

.calendarContainer{
    height:"100px";
    background-color:red;
}

.myFullApp{
  top:0;
  bottom:0;
}

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #AAA;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #222;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
  -moz-box-shadow: 0px 0px 3px 2px #0099E0;
  box-shadow: 0px 0px 2px 3px #0099E0;
}

/*.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
  -moz-box-shadow: 0px 0px 5px 5px #0099E0;
  box-shadow: 0px 0px 5px 5px #0099E0;
}
*/

@keyframes grow {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}


.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo-cont {
  padding: 30px;
  width: 100px;
  height: 100px;
  position: relative;
  border-radius: 50%;
  background: #00ba69;
  float: left;
  margin: 50px 100px;
  z-index: 30;
}

.logo-noanim {
  padding: 30px;
  width: 60;
  height: 60px;
  position: relative;
  border-radius: 50%;
  background: #00ba69;
  z-index: 30;
  //margin-top: 10px;
  //margin-bottom: 30px;
}

.logo-cont::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  border-radius: 50%;
  border: solid 2px #00ba69;
  top: 0;
  left: 0;
  animation: grow 1s 1.5s infinite ease-out;
}

/* Delay showing spinner first, then gradually let it fade in. */
.fallback-fadein {
  visibility: hidden;
  animation: fadein 1.0s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s; /* no spinner flickering for fast load times */
}

@keyframes fadein {
  from {
    visibility: visible;
    opacity: 0;
  }
  to {
    visibility: visible;
    opacity: 1;
  }
}

.fallback-fadeout {
  animation: fadeout 0.5s;
  animation-fill-mode: forwards;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.notebook{
  border: 1px solid #EEEEEE;
  box-shadow: 1px 1px 0 #DDDDDD;
  display: block;
  font-family: 'Marck Script',cursive;
  font-size: 22px;
  line-height: 50px;
  margin: 2% auto;
  padding: 11px 20px 0 70px;
  resize: none;
  height: 689px;
  width: 530px;

  background-image: -moz-linear-gradient(top , transparent, transparent 49px,#E7EFF8 0px), -moz-radial-gradient(4% 50%, circle closest-corner, #f5f5f5, #f5f5f5 39%, transparent 0%), -moz-radial-gradient(3.9% 46% , circle closest-corner, #CCCCCC, #CCCCCC 43.5%, transparent 0%); 
  background-image: -webkit-linear-gradient(top , transparent, transparent 49px,#E7EFF8 0), -webkit-radial-gradient(4% 50%, circle closest-corner, #f5f5f5, #f5f5f5 39%, transparent 0%), -webkit-radial-gradient(3.9% 46%, circle closest-corner, #cccccc, #cccccc 43.5%, transparent 0%);

  -webkit-background-size:  100% 50px;
  background-size: 100% 50px;
}

.input:focus {
    outline: none !important;
    border:1px solid red;
    box-shadow: 0 0 10px #719ECE;
}


/*.modal-dialog{
   min-width:"800px";
   margin: auto;
}*/

.modal-xl{
  max-width: 1400px;
}



@media (max-width: 1400px) {
  .modal-xl{ 
    max-width: 95%; 
  }
}


.quote{
  font-family: 'Dancing Script', cursive;
  text-transform: capitalize; 
}




@keyframes textfadein {
  0% {

    opacity: 0;
    letter-spacing: 20px;
  }


  100% {
    opacity: 1;
    letter-spacing: 10px;
  }
}


.text-anim {
  opacity: 0;
  animation: textfadein 3s 1.4s ease forwards;
}

.after-logo {
  animation-delay: 4.0s;
}

.path1 {
  opacity: 0;
  animation: textfadein 1s ease forwards;
}

.path2 {
  opacity: 0;
  animation: textfadein 1s 0.2s ease forwards;
}

.path4 {
  opacity: 0;
  animation: textfadein 1s 0.4s ease forwards;
}

.path3 {
  opacity: 0;
  animation: textfadein 1s 0.6s ease forwards;
}

.path5 {
  opacity: 0;
  animation: textfadein 1s 0.8s ease forwards;
}

.path6 {
  opacity: 0;
  animation: textfadein 1s 1.0s ease forwards;
}

.path7 {
  opacity: 0;
  animation: textfadein 1s 1.2s ease forwards;
}

.path8 {
  opacity: 0;
  animation: textfadein 1s 1.0s ease forwards;
}

.path9 {
  opacity: 0;
  animation: textfadein 1s 0.8s ease forwards;
}

.path10 {
  opacity: 0;
  animation: textfadein 1s 0.6s ease forwards;
}

.path11 {
  opacity: 0;
  animation: textfadein 1s 0.4s ease forwards;
}

.path12 {
  opacity: 0;
  animation: textfadein 1s 0.2s ease forwards;
}

.path13 {
  opacity: 0;
  animation: textfadein 1s ease forwards;
}



.modal-backdrop {
  background-color: #444;
}

.inner-shadow {
  box-shadow: inset rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.rbt-token {
  color: #FFF !important;
  background-color: #8ba6aa !important;
  border-radius: px !important;
}

.rbt-input-multi.form-control{
  border: 0;
  padding: 5px;
}

.loader-not-ready{
 height: "100vh";
}

.loader-img{
  max-width:"400px";
}


.react-datepicker__day--highlighted{
  background-color : #EEE !important;
  color: #222 !important;
}

.react-datepicker__day--keyboard-selected{
  background-color : #888 !important;
  color: #FFF !important;
}

.react-datepicker__day--today{
  background-color: #bed5c4 !important;
  border-radius: 0.3rem;
}


@import url('https://fonts.googleapis.com/css2?family=Lato&family=Questrial&display=swap');

h5{
  font-family: 'Lato', sans-serif;
}

.modalTitle{
  font-family: 'Lato', sans-serif;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.hidebar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidebar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.readmore{
  cursor: pointer;
}
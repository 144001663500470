// If you want to override variables do it here

$body_bg: #f5f5fb;

$theme-colors: (
  "primary": #8ba6aa, //#6daf72,
  "secondary": #bed5c4, //#b2dbb5,
  "info": #9698d6,
  "danger": #ff96a6,
  "success": #d9e7dcff //#D7ECD9,
); 

$sidebar-light-nav-link-color:       rgba(#000, .4);

$sidebar-light-nav-link-hover-bg:  rgba(#FFF, .8);
$sidebar-light-nav-link-hover-color:  rgba(#000, 1);
$sidebar-light-nav-link-hover-icon-color:  #4d4cac;

@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";



// If you want to add something do it here
@import "custom";


